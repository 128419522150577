import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_42_1128)">
            <path d="M0 0.944443H4.21222L13.0994 27.3606H30.1183" stroke="#00008A" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M5.92163 6.02556H36.4366L31.7711 20.2489H10.7666" stroke="#00008A" strokeWidth="2" strokeMiterlimit="10" />
            <path
                d="M30.1184 33.0556C31.6884 33.0556 32.9612 31.7828 32.9612 30.2128C32.9612 28.6428 31.6884 27.37 30.1184 27.37C28.5484 27.37 27.2756 28.6428 27.2756 30.2128C27.2756 31.7828 28.5484 33.0556 30.1184 33.0556Z"
                stroke="#00008A"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M13.1089 33.0556C14.6789 33.0556 15.9517 31.7828 15.9517 30.2128C15.9517 28.6428 14.6789 27.37 13.1089 27.37C11.5389 27.37 10.2661 28.6428 10.2661 30.2128C10.2661 31.7828 11.5389 33.0556 13.1089 33.0556Z"
                stroke="#00008A"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </g>
        <defs>
            <clipPath id="clip0_42_1128">
                <rect width="37.74" height="34" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon;
